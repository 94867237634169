import React from 'react';
import { useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'gatsby-theme-material-ui';

const useStyles = makeStyles((theme: Theme) => createStyles({
  image: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey.A100}`,
  },
}));

interface props {
  verticalImage?: IGatsbyImageData;
  horizontalImage?: IGatsbyImageData;
  alt: string;
  to: string;
}

const HomeCoverImage: React.FC<props> = ({
  horizontalImage,
  verticalImage,
  alt,
  to,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  // mobile first
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Link to={to}>
      {horizontalImage
        && (
          <GatsbyImage
            image={isDownSm ? verticalImage ?? horizontalImage : horizontalImage}
            className={classes.image}
            alt={alt}
          />
        )}
    </Link>
  );
};

export default HomeCoverImage;
