import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import { createStyles, Grid, Paper, Theme, Typography, useTheme, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import HomeCoverImage from './components/homeCoverImage';
import Perks from '../../shared-components/perks';
import Meta from '../../shared-components/meta';
import { TranslationService } from '../../services/translationService';
import { translationServiceContext } from '../../services/provider';
import Reviews from "./components/reviews";
import { formatISO } from "date-fns";
import { generatePostalAddressStreet } from "../../utils/general";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "gatsby-theme-material-ui";
import { requireNonNull } from '../../utils/operators';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  fullWidth: {
    width: '100%',
  },
  fontWeight: {
    fontWeight: 500
  },
  zeroPadding: {
    padding: 0
  }
}));

interface ImageRelativePath {
  readonly relativePath: string;
  readonly childImageSharp: IGatsbyImageData;
}

interface Props {
  readonly images: {
    readonly nodes: ReadonlyArray<ImageRelativePath>
  };
  readonly home: {
    readonly frontmatter: {
      readonly descriptionTitle: string;
      readonly descriptionText: string;
      readonly horizontalCoverImage: string;
      readonly verticalCoverImage: string;
      readonly cards: ReadonlyArray<{
        readonly title: string;
        readonly image: string;
        readonly link: string;
      }>
    };
  };
  readonly general: {
    readonly frontmatter: {
      readonly title: string;
      readonly slogan?: string;
      readonly description?: string;
    };
  };
  readonly reviews: ReviewsWrapper;
  readonly contact: {
    readonly frontmatter: {
      readonly stores: ReadonlyArray<{
        readonly id: string;
        readonly address: {
          readonly line1: string;
          readonly line2: string | undefined;
          readonly city: string;
          readonly postalCode: string;
          readonly country: string;
        };
        readonly googleMapsAddReviewLink: string;
      }>;
      readonly phoneNumber: string;
    }
  }
}

export interface Review {
  readonly comment?: string | null;
  readonly starRating: number;
  readonly reviewer: {
    readonly profilePhotoUrl: string;
    readonly displayName: string
  };
  readonly createTime: string;
  readonly updateTime: string;
}

export interface ReviewsWrapper {
  readonly reviews: ReadonlyArray<Review>;
  readonly totalReviewCount: number;
  readonly averageRating: number;
}

const Home: React.FC<PageProps<Props>> = (props) => {
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const classes = useStyles();
  const theme = useTheme();

  // mobile first
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const {
    images,
    home: {
      frontmatter: {
        horizontalCoverImage,
        verticalCoverImage,
        descriptionTitle,
        descriptionText,
        cards
      }
    },
    general,
    contact
  } = props.data;

  const imagesMap: Map<string, ImageRelativePath> = new Map<string, ImageRelativePath>(images.nodes.map(node => [node.relativePath, node]));

  const horizontalImage: IGatsbyImageData | undefined = getImage(requireNonNull(imagesMap.get(horizontalCoverImage)).childImageSharp);
  const verticalImage: IGatsbyImageData | undefined = verticalCoverImage ? getImage(requireNonNull(imagesMap.get(verticalCoverImage)).childImageSharp) : undefined;

  const pageCanonicalURL = `${process.env.GATSBY_APP_URL}/`;

  const {
    title,
    slogan,
    description
  } = general.frontmatter;

  const pageTitle: string = `${title}${slogan ? ` | ${slogan}` : ''}`;

  const {
    stores,
    phoneNumber
  } = contact.frontmatter;

  const reviewsWrapper = props.data.reviews;

  const store = stores[0];

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "Store",
    "name": "YOUMNI Boutique",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": generatePostalAddressStreet(store.address.line1, store.address.line2),
      "addressLocality": store.address.city,
      "postalCode": store.address.postalCode,
      "addressCountry": store.address.country
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 33.6118013,
      "longitude": -7.5022979
    },
    "telephone": phoneNumber,
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": reviewsWrapper.averageRating,
      "reviewCount": reviewsWrapper.totalReviewCount
    },
    "review": reviewsWrapper.reviews.map(review => ({
      "@type": "Review",
      "author": {
        "@type": "Person",
        "name": review.reviewer.displayName
      },
      "datePublished": formatISO(new Date(review.createTime), {
        format: "extended",
        representation: "date"
      }),
      "reviewBody": review.comment,
      "reviewRating": {
        "@type": "Rating",
        "bestRating": 5,
        "ratingValue": review.starRating,
        "worstRating": 1
      }
    })),
    "url": pageCanonicalURL
  }

  return (
    <>
      <Meta
        title={pageTitle}
        siteTitle={title}
        description={description}
        url={pageCanonicalURL}
        ldJson={ldJson}
      />
      <Container className={classes.container}>
        <Grid container direction="row" spacing={4} justifyContent="center" alignItems="center">
          <Grid item className={classes.fullWidth}>
            <Grid item style={{
              marginBottom: 12
            }}>
              <HomeCoverImage
                horizontalImage={horizontalImage}
                verticalImage={verticalImage}
                alt={translationService.translate('HOME_COVER_IMAGE_TITLE')}
                to="/nouveautes/"
              />
            </Grid>
            <Grid item className={classes.fullWidth}>
              <Perks asPaper={true} />
            </Grid>
          </Grid>
          <Grid item className={classes.fullWidth}>
            <Typography variant="h4" component="h1" gutterBottom align="center" className={classes.fontWeight}>
              {descriptionTitle}
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              {descriptionText}
            </Typography>
          </Grid>
          <Grid item container spacing={2} className={classes.fullWidth}>
            {cards.map(card => (
              <Grid item xs={isDownSm ? 12 : 6}>
                <Link to={card.link}>
                  <Paper variant="outlined" elevation={0} className={classes.zeroPadding}>
                    <GatsbyImage image={requireNonNull(getImage(requireNonNull(imagesMap.get(card.image)).childImageSharp))} alt={card.title} />
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Grid item className={classes.fullWidth}>
            <Reviews reviews={reviewsWrapper} addReviewLink={store.googleMapsAddReviewLink} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Home;

export const getHomePageData = graphql`
    query GetHomePageData($imagesUri: [String]) {
        images: allFile(filter: {relativePath: { in: $imagesUri }}) {
            nodes {
                relativePath
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
        }
        general: markdownRemark(fileAbsolutePath: { regex: "/settings/general.md$/" }) {
            frontmatter {
                title
                slogan
                description
            }
        }
        home: markdownRemark(fileAbsolutePath: { regex: "/settings/home-page.md$/" }) {
            frontmatter {
                horizontalCoverImage
                verticalCoverImage
                descriptionTitle
                descriptionText
                cards {
                    title
                    image
                    link
                }
            }
        }
        contact: markdownRemark(fileAbsolutePath: { regex: "/settings/contact.md$/" }) {
            frontmatter {
                stores {
                    id
                    address {
                        line1
                        line2
                        postalCode
                        city
                        country
                    }
                    googleMapsAddReviewLink
                }
                phoneNumber
            }
        }
        reviews: googleReviews {
            averageRating
            totalReviewCount
            reviews {
                comment
                createTime
                starRating
                updateTime
                reviewer {
                    displayName
                    profilePhotoUrl
                }
            }
        }
    }
`;
