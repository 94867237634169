import React, { useContext } from 'react';
import {
  Divider, List, ListItem, ListItemAvatar, ListItemText, Paper, useTheme,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import { translationServiceContext } from '../services/provider';
import { TranslationService } from '../services/translationService';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    backgroundColor: theme.palette.grey['50'],
    border: `1px solid ${theme.palette.grey.A100}`,
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface Props {
  readonly asPaper: boolean;
}

const Perks: React.FC<Props> = ({ asPaper = true }) => {
  // Inject required services
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const classes = useStyles();
  const theme = useTheme();

  // mobile first
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const services = (
    <List className={!isDownSm ? classes.list : undefined}>
      <ListItem>
        <ListItemAvatar>
          <LocalShippingOutlinedIcon fontSize="large" color="primary" />
        </ListItemAvatar>
        <ListItemText
          /* primaryTypographyProps={{
            component: "h3"
          }} */
          primary={translationService.translate('HOME_DELIVERY')}
          secondary={translationService.translate('HOME_DELIVERY_DETAILS')}
        />
        <Divider orientation="vertical" variant="middle" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <PaymentOutlinedIcon fontSize="large" color="primary" />
        </ListItemAvatar>
        <ListItemText
          primary={translationService.translate('HOME_PAYMENT_ON_DELIVERY')}
          secondary={translationService.translate('HOME_PAYMENT_ON_DELIVERY_DETAILS')}
        />
        <Divider orientation="vertical" variant="middle" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <HeadsetMicOutlinedIcon fontSize="large" color="primary" />
        </ListItemAvatar>
        <ListItemText
          primary={translationService.translate('HOME_CLIENT_SERVICE')}
          secondary={translationService.translate('HOME_CLIENT_SERVICE_DETAILS')}
        />
        <Divider orientation="vertical" variant="middle" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <BeenhereOutlinedIcon fontSize="large" color="primary" />
        </ListItemAvatar>
        <ListItemText
          primary={translationService.translate('HOME_RETURNING_YOUR_PRODUCTS')}
          secondary={translationService.translate('HOME_RETURNING_YOUR_PRODUCTS_DETAILS')}
        />
      </ListItem>
    </List>
  );

  return (
    asPaper ? (
      <Paper className={classes.paper} elevation={0}>
        {services}
      </Paper>
    ) : services
  );
};

export default Perks;
