import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  siteTitle: string;
  description?: string;
  url?: string;
  imageSrc?: string;
  imageTitle?: string;
  ldJson?: object;
}

const Meta: React.FC<Props> = ({
  title,
  siteTitle,
  description,
  url,
  imageSrc,
  imageTitle,
  children,
  ldJson
}) => (
  <Helmet
    htmlAttributes={{
      lang: 'fr',
    }}
  >
    <title>{title}</title>
    <link rel="canonical" href={url} />
    {description && [
      <meta key="description" name="description" content={description} />,
      <meta key="og:description" property="og:description" content={description} />,
    ]}
    {url && <meta property="og:url" content={url} />}
    <meta property="og:title" content={title} />
    <meta property="og:site_name" content={siteTitle} />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="fr_FR" />
    <meta key="og:image" property="og:image" content={imageSrc} />
    <meta key="og:image:alt" property="og:image:alt" content={imageTitle} />
    <script type="application/ld+json">
      {JSON.stringify(ldJson)}
    </script>
    {children}
  </Helmet>
);

export default Meta;
