import React, {useContext, useState} from 'react';
import {Avatar, Box, createStyles, Grid, Theme, Typography, useTheme,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Rating} from "@material-ui/lab";
import Masonry from "react-masonry-css";
import {formatDistance} from 'date-fns'
import {fr} from 'date-fns/locale'
import {useRowFlexStyles} from '@mui-treasury/styles/flex/row';
import {Button} from "gatsby-theme-material-ui";
import {Review, ReviewsWrapper} from "../home";
import ExternalLink from "../../../shared-components/externalLink";
import {TranslationService} from "../../../services/translationService";
import {translationServiceContext} from "../../../services/provider";

interface Props {
  readonly reviews: ReviewsWrapper;
  readonly addReviewLink: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  pos: {
    marginBottom: 12,
  },
  masonryGrid: {
    display: 'flex',
    marginLeft: theme.spacing(-4),
    width: 'auto',
    marginBottom: theme.spacing(1),
  },
  masonryColumn: {
    paddingLeft: theme.spacing(4),
    backgroundClip: 'padding-box',
  },
  inlineDisplay: {
    display: 'inline-block',
  },
  fontWeight: {
    fontWeight: 500
  },
  fullWidth: {
    width: '100%'
  }
}));

const Reviews: React.FC<Props> = ({reviews, addReviewLink}) => {

  // Inject required services
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  // Styles
  const classes = useStyles();
  const theme: Theme = useTheme();

  const [reviewsToShow, setReviewsToShow] = useState(reviews.reviews.slice(0, 6));

  const breakpoints = {
    [theme.breakpoints.values.lg]: 3,
    [theme.breakpoints.values.md]: 2,
    [theme.breakpoints.values.sm]: 1,
  };

  return (
    <Grid container spacing={5}>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="h4" component="h2" gutterBottom align="center" className={classes.fontWeight}>
            {translationService.translate("HOME_GOOGLE_REVIEWS_TITLE")}
          </Typography>
        </Grid>
        <Grid item>
          <Rating value={reviews.averageRating} readOnly className={classes.pos}/>
          <Typography variant="body1" paragraph align="center">
            <Box fontWeight="500" display="inline" component="b">{reviews.averageRating}</Box> notes
            sur {reviews.totalReviewCount} avis
          </Typography>
        </Grid>
        <Grid item>
          <ExternalLink href={addReviewLink}>
            <Button variant="contained" color="primary">
              {translationService.translate("HOME_ADD_GOOGLE_REVIEW")}
            </Button>
          </ExternalLink>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" direction="column">
        <Grid item className={classes.fullWidth}>
          <Masonry
            breakpointCols={{
              default: breakpoints[theme.breakpoints.values.lg],
              [theme.breakpoints.values.md]: breakpoints[theme.breakpoints.values.md],
              [theme.breakpoints.values.sm]: breakpoints[theme.breakpoints.values.sm],
            }}
            columnClassName={classes.masonryColumn}
            className={classes.masonryGrid}
          >
            {reviewsToShow.map(review => (
              <ReviewCard key={review.createTime} review={review}/>
            ))}
          </Masonry>
        </Grid>
        {reviewsToShow.length < reviews.reviews.length && (
          <Grid item>
            <Button size="small" variant="outlined" onClick={() => {
              setReviewsToShow(reviewsToShow.concat(reviews.reviews.slice(reviewsToShow.length, reviewsToShow.length + 6)));
            }}>
              Voir plus
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

interface ReviewCardProps {
  readonly review: Review
}

const ReviewCard: React.FC<ReviewCardProps> = ({review}) => {

  const classes = useStyles();
  const readableCreationDate: string = formatDistance(new Date(review.createTime), new Date(), {
    addSuffix: true,
    locale: fr
  });
  const flexStyles = useRowFlexStyles();

  return (
    <Card style={{marginBottom: "20px"}} variant="outlined" component="article">
      <CardContent>

        <Box className={flexStyles.parent} marginBottom={1}>
          <Box className={classes.inlineDisplay} marginRight={1}>
            <Avatar alt={review.reviewer.displayName} src={review.reviewer.profilePhotoUrl}/>
          </Box>
          <Box className={classes.inlineDisplay}>
            <Typography variant="h6" component="h2">
              {review.reviewer.displayName}
            </Typography>
          </Box>
        </Box>

        <Rating name="read-only" value={review.starRating} readOnly className={classes.pos}/>
        {review.comment && (
          <Box marginBottom={2}>
            <Typography variant="body1" component="p" gutterBottom>
              {review.comment}
            </Typography>
          </Box>
        )}

        <Box className={flexStyles.parent} component="footer">
          <Box className={classes.inlineDisplay} marginRight={1}>
            <img src={"/images/google_icon.png"} width={25} alt={"Google Review"}/>
          </Box>
          <Box className={classes.inlineDisplay}>
            <Typography variant="caption" component="p" color="textSecondary">
              <time dateTime={review.createTime}>{readableCreationDate}</time>
            </Typography>
          </Box>
        </Box>

      </CardContent>
    </Card>
  );
}

export default Reviews;
